!(function(jQ){
    
    'use strict';

    ABT.Utility = {
        // Validates and Parses a valid JSON string
        strToJSON: function (str, error)  {
            try {
                var o = JSON.parse(str);
                if (o && typeof o === 'object' && o !== null) return o;
            } catch (e) {
                if ((typeof error !== 'undefined') && (error === true)) {
                    console.log(e);
                }
            }
            return false;
        },
    };

    ABT.GoogleMaps = {

        initMap: function () {
            var opts = {
                zoom: this.zoom,
                zoomControl: true,
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.SMALL,
                    position: google.maps.ControlPosition.RIGHT_BOTTOM
                },
                center: this.center,
                panControl: true,
                scrollwheel: false,
                rotateControl: true,
                streetViewControl: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.TOP_RIGHT
                }
            },
            defStyles = [{
                stylers: [{ gamma: 0.73 }, { saturation: -100 }]
                }, {
                featureType: 'transit',
                elementType: 'labels.icon',
                stylers: [{ gamma: 0 }, { saturation: 0 }]
            }];

            opts.styles = this.mapStyles ? this.mapStyles : defStyles;

            this.map = new google.maps.Map(this.wrapper[0], opts);

            // this.map.addListener('idle', function () {
            //     this.resetView();
            // }.bind(this));

            jQ(window).on('resize', null, { _this: this }, function (e) {
                e.data._this.centerMap();
            });

            this.populate();
        },

        centerMap: function () {
            google.maps.event.trigger(this.map, 'resize');
            this.map.panTo(this.center);
        },

        populate: function () {
            var id, type, coords, content, defaultIcon, mainMarker, 
                bounds = new google.maps.LatLngBounds();
                defaultIcon = new google.maps.MarkerImage(
                    this.defIcon,
                    new google.maps.Size(30, 30),
                    new google.maps.Point(0, 0),
                    new google.maps.Point(15, 15),
                    new google.maps.Size(30, 30)
                );

            this.data = null;
            this.markers = {
                0: {},  // all
                1: {},  // stop 
                2: {}   // appr
            };
            
            // this.mainMarker  = new google.maps.LatLng(46.931977, 7.419667);
            
            // this.markers[0]['center'] = new google.maps.Marker({
            //     map: this.map,
            //     position: this.mainMarker,
            //     title: 'SGUV'
            // });

            this.infoWindow = new google.maps.InfoWindow({
                content: this.infoCnt,
                position: this.center
            });
            
            for (var poi in this.mapPOIs) {
                this.data = this.mapPOIs[poi];
                
                if (!this.data || this.data['coords'] === null) continue;
                if (!this.data['coords'][0] || !this.data['coords'][1]) continue;

                id = this.data['identifier'];
                coords = new google.maps.LatLng(this.data['coords'][0], this.data['coords'][1]);
                content = this.generateInfoWindowContent(this.data);

                this.markers[0][id] = new google.maps.Marker({
                    map: this.map,
                    icon: defaultIcon,
                    title: this.data['title'],
                    position: coords
                });

                google.maps.event.addListener(this.markers[0][id], 'click', (function(id, coords, content){
                    return function() {

                        this.infoWindow.setContent(content);
                        this.infoWindow.setPosition(coords);
                        this.infoWindow.open(this.map, this.markers[0][id]);
                        
                        google.maps.event.addListener(this.map, 'click', function(){ 
                            this.infoWindow.close();
                        }.bind(this));
                        
                        this.controls.find('input[type="checkbox"]').on('click', function(){ 
                            this.infoWindow.close();
                        }.bind(this));

                    }.bind(this);
                }.bind(this))(id,coords,content));

                if(this.data['stop']){
                    this.markers[1][id] = this.markers[0][id];
                }
                if(this.data['appr']){
                    this.markers[2][id] = this.markers[0][id];  
                }

                this.markers[0][id].setVisible(true);
                
            }

            this.centerMap();
            this.bindControls();
            this.wrapper.addClass('ready');
        },

        togglePOIs: function (type, status) {
            var latLon = null,
                adjust = false,
                bounds = this.map.getBounds();

            if (!this.markers[type]) return;

            status = status === true ? true : false;

            for (var poi in this.markers[type]) {
                latLon = new google.maps.LatLng(this.markers[type][poi].getPosition().lat(), this.markers[type][poi].getPosition().lng());

                this.markers[type][poi].setVisible(status);

                if (status == true && !bounds.contains(latLon)) {
                    adjust = true;

                    bounds.extend(latLon);
                }
            }

            if (adjust) {
                this.map.fitBounds(bounds);
                this.resetView();
            }
        },

        generateInfoWindowContent: function(data){
            if(!data) return;

            var content =  '<div id="content">'+
                            '<div id="siteNotice"></div>';
            
            if(data['title']){
                content += '<h3 id="firstHeading" class="firstHeading">'+ data['title'] +'</h3>';
            }
            
            content += '<div id="bodyContent">';

            if(data['address'] ||  data['zip'] || data['city']){
                content += data['address'] + '<br>' + data['zip'] +' '+ data['city'] + '<br><br>';
            }

            if(data['phone'] ||  data['email']){
                content += data['phone'] + '<br>' + data['email'];
            }
            
            return content += '</div></div>';
        },

        bindControls: function () {
            this.controls.find('input[type="checkbox"]').on('click', null, { _this: this }, function (e) {
                var check = jQ(this),
                    poiSet = check.attr('data-poitype') || '',
                    active = check.attr('data-active') == '1';
                
                // reset active checkboxses
                e.data._this.controls.find('input[data-active]').removeAttr('data-active').prop('checked', '');

                if (active) {
                    e.data._this.togglePOIs(poiSet, false);
                    check.attr('data-active', '0').prop('checked', '');
                    //show all labels again
                    e.data._this.togglePOIs(0, true);
                } else {
                    //hide all labels
                    e.data._this.togglePOIs(0, false);
                    e.data._this.togglePOIs(poiSet, true);
                    check.attr('data-active', '1').prop('checked', 'checked');
                }
            }).prop('checked', '');
        },

        boot: function () {
            var tmr = setInterval(function () {
                if (this.mapStyles != null) {
                    clearInterval(tmr);
                    this.initMap();
                }
            }.bind(this), 50);         
        },

        init: function () {

            this.zoom       = 8;
            this.minZoom    = this.zoom;
            this.maxZoom    = this.zoom - 2;
            this.center     = {lat: 46.801111, lng: 8.226667};
            this.map        = null;
            this.marker     = {lat: 46.801111, lng: 8.226667};
            this.markers    = null;
            this.mapStyles  = null;
            this.userGeo    = null;
            this.defIcon    = null;
            this.destIcon   = null;
            this.dirDisplay = null;
            this.dirService = null;
            this.infoWin    = true;
            this.infoCnt    = '';
            this.wrapper    = jQ('.abte-map .map').eq(0);
            this.controls   = jQ('.abte-map .controls').eq(0);
            this.mapPOIs    = ABT.Utility.strToJSON(jQ('.abte-map .markers').eq(0).html() || '');
            this.mapStyles  = CONF.googleMapStyles;
            
            if (!this.wrapper.length) return;
            
            this.defIcon    = this.wrapper.attr('data-marker');

            !function loadMapScript() {
                var mapsCallback = 'ABT.GoogleMaps.initMap',
                    script = document.createElement('script'),
                    apiKey = 'AIzaSyBpYhOaGZHXXVvb1T9TO3INV-ENzH1JPz8';

                script.type = 'text/javascript';
                script.setAttribute('defer', 'defer');
                script.setAttribute('async', 'async');
                script.src = 'https://maps.googleapis.com/maps/api/js?key='+apiKey+'&callback='+mapsCallback;

                document.head.appendChild(script);
            }();

        },
       
    };

    ABT.GoogleMaps.init();

})(jQuery);